import {type PropsWithChildren} from 'react'
import {HgThemeSwitcher} from '~/design-system/hg/components'
import {getDarkThemeName, getThemeName} from '~/design-system/hg/tokens/colors'
import {type ThemeSwitcherFragment} from './_generated/fragments.graphql'

type DatoThemeSwitcherProps = {
  applyGlobally?: boolean
  theme: ThemeSwitcherFragment
  darkThemeOnly?: boolean
} & PropsWithChildren

export function DatoThemeSwitcher(props: DatoThemeSwitcherProps) {
  const {children, theme, applyGlobally, darkThemeOnly} = props

  const themeName = darkThemeOnly
    ? getDarkThemeName(theme.name)
    : getThemeName(theme.name)

  return (
    <HgThemeSwitcher theme={themeName} applyGlobally={applyGlobally}>
      {children}
    </HgThemeSwitcher>
  )
}
