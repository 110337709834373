import {type HgHeroCTAProps} from '~/design-system/hg/modules'
import {getEmailCaptureDestinationProps} from '~/cms/helpers/getEmailCaptureProps/getEmailCaptureDestinationProps'
import {getLinkProps} from '~/cms/components/LinkComponent'
import {
  type Node,
  type Root,
  type StructuredText,
  isDocument,
} from 'datocms-structured-text-utils'
import {type PartnerPageLogo, type HeroSectionProps} from '../_types'
import {type CSSProperties} from 'react'
import LogoSVG from '~/cms/modules/_FEM/LogoMash/LogoSVG'
import {Image as ReactDatoImage} from 'react-datocms'

export function getCTATypeProps(data: HeroSectionProps['data']): HgHeroCTAProps {
  if (data.ctaType === 'email_capture' && data.emailCapture) {
    return {
      contentType: 'emailCapture',
      emailCaptureProps: getEmailCaptureDestinationProps(data.emailCapture),
    }
  }

  return {
    contentType: 'buttonGroup',
    primaryButtonProps: getLinkProps(data.primaryButton),
    secondaryButtonProps: getLinkProps(data.secondaryButton),
  }
}

export function structuredTextCharacterCount(structuredText: StructuredText) {
  let totalCharacters = 0

  function countCharacters(node: Node | Root) {
    if ('value' in node && node.value) {
      totalCharacters += node.value.length
    }

    if ('children' in node && node.children) {
      node.children.forEach(child => {
        countCharacters(child)
      })
    }
  }

  if (isDocument(structuredText.value)) {
    countCharacters(structuredText.value.document)
  }

  return totalCharacters
}

export function getLogo(logoData?: PartnerPageLogo) {
  if (!logoData) {
    return
  }

  if (typeof logoData === 'string') {
    return logoData
  }

  if (logoData.logo.format === 'svg') {
    return (
      <div
        style={
          // TODO: figure out why codegen types most things as any
          {'--height': `${(logoData.height as number) ?? 24}px`} as CSSProperties
        }
        className="[&_svg]:h-[var(--height)] [&_svg]:w-full"
      >
        <LogoSVG logo={logoData} />
      </div>
    )
  }

  if (!logoData.logo.responsiveImage) {
    return
  }

  return (
    <ReactDatoImage
      priority
      data={{...logoData.logo.responsiveImage, alt: logoData.companyName}}
    />
  )
}
