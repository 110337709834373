import {shaderMaterial} from '@react-three/drei'
import {type ThreeElement} from '@react-three/fiber'
import * as THREE from 'three'
import {colors} from '~/design-system/hg/tokens'
import fragmentShader from '../_shaders/fragment.glsl'
import vertexShader from '../_shaders/vertex.glsl'

const BASE_POINT_COLOR = colors.beige.base[300]

export const CustomShaderMaterial = shaderMaterial(
  {
    uColor: new THREE.Color(BASE_POINT_COLOR),
    uOpacity: 0.5,
  },
  vertexShader,
  fragmentShader
)

// unfortunately, we have to extend the ThreeElements interface in order to use it without any type errors
// https://r3f.docs.pmnd.rs/tutorials/typescript#extending-threeelements
declare module '@react-three/fiber' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ThreeElements {
    customShaderMaterial: ThreeElement<typeof CustomShaderMaterial>
  }
}
