import {type AspectRatio} from '~/design-system/hg/components'
import {DatoImage, type NoDataSRCImageProps} from '../DatoImage'
import DatoAnimation, {type DatoAnimationProps} from '../DatoVideo'
import {
  type ThreeTwoAssetFragment,
  type OneOneAssetFragment,
  type TwoOneAssetFragment,
  type FourFiveAssetFragment,
  type SixteenNineAssetFragment,
  type SixteenFiveAssetFragment,
  type NineSixteenAssetFragment,
} from './_generated/fragments.graphql'
import {themeToClassName, type Theme} from '~/design-system/hg/tokens/colors'
import {type GenericAssetFragment} from '../_blog/ArticleHero/_generated/fragment.graphql'

const Asset = ({
  data,
  aspectRatio,
  datoImageProps,
  datoAnimationProps,
}: {
  data:
    | TwoOneAssetFragment
    | OneOneAssetFragment
    | ThreeTwoAssetFragment
    | FourFiveAssetFragment
    | SixteenNineAssetFragment
    | SixteenFiveAssetFragment
    | NineSixteenAssetFragment
    | GenericAssetFragment
  aspectRatio: AspectRatio
  datoImageProps?: NoDataSRCImageProps
  datoAnimationProps?: Omit<DatoAnimationProps, 'data' | 'aspectRatio'>
}) => {
  const {asset} = data
  switch (asset.__typename) {
    case 'ImageBlockRecord': {
      return <DatoImage data={asset} {...datoImageProps} />
    }
    case 'VideoBlockRecord': {
      return (
        <DatoAnimation
          data={asset}
          controlsTheme={
            (asset.controlsTheme && asset.controlsTheme.name in themeToClassName
              ? asset.controlsTheme.name
              : 'neutral') as Theme
          }
          aspectRatio={aspectRatio}
          {...datoAnimationProps}
        />
      )
    }
    default:
      return null
  }
}

export default Asset
