import {isLink, isList, isListItem, isParagraph} from 'datocms-structured-text-utils'
import {Fragment} from 'react'
import {
  StructuredText,
  renderMarkRule,
  renderNodeRule,
} from 'react-datocms/structured-text'
import DisclaimerLink from '~/cms/components/SingleLineStructuredText/DisclaimerLink'
import FEMLink from '~/components/FEMLink'
import IO from '~/components/IO'
import {DatoConstant} from '../Constants/DatoConstant'
import {type SingleLineStructuredTextFragment} from './_generated/fragment.graphql'

type SimpleStructuredTextProps = {
  data?: SingleLineStructuredTextFragment | null
}
export function SingleLineStructuredText(props: SimpleStructuredTextProps) {
  const {data} = props
  if (!data) return null
  return (
    <Fragment key={data.id}>
      <StructuredText
        customNodeRules={[
          renderNodeRule(isParagraph, ({children, key}) => {
            return <span key={key}>{children}</span>
          }),
          renderNodeRule(isLink, ({children, node, key}) => {
            return (
              <FEMLink
                key={key}
                href={node.url}
                className="text-inherit"
                variant="underline"
              >
                {children}
              </FEMLink>
            )
          }),
          renderNodeRule(isList, ({node, children, key, adapter: {renderNode}}) => {
            if (node.style === 'bulleted') {
              return renderNode(
                'ul',
                {key, className: 'list-square list-outside pl-s5'},
                children
              )
            }
            return renderNode(
              'ol',
              {key, className: 'list-decimal list-outside pl-s5'},
              children
            )
          }),
          renderNodeRule(isListItem, ({children, key, adapter: {renderNode}}) => {
            return renderNode('li', {key, className: 'pl-s3'}, children)
          }),
        ]}
        customMarkRules={[
          renderMarkRule('superscript', ({children, key}) => {
            return <sup key={key}>{children}</sup>
          }),
          renderMarkRule('io-font', ({key}) => {
            return <IO key={key} />
          }),
          renderMarkRule('strong', ({children, key}) => {
            return (
              <strong className="font-medium" key={key}>
                {children}
              </strong>
            )
          }),
        ]}
        renderLinkToRecord={({record}) => {
          switch (record.__typename) {
            case 'ConstantRecord':
              return <DatoConstant data={record} />
            case 'DisclaimerRecord':
              return <DisclaimerLink {...record} text={record.text} />
            default:
              return null
          }
        }}
        data={data.text}
      />
    </Fragment>
  )
}
