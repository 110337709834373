'use client'

import {useRef, useLayoutEffect, useState} from 'react'
import {HgLoadingDots} from '~/design-system/hg/components'

type IframeProps = {
  src: string
  height: number
  mobileHeight: number
}

const Iframe = ({src, height, mobileHeight}: IframeProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [currentHeight, setCurrentHeight] = useState<number | undefined>()

  /* Since Glide has defined breakpoints based on the size of its window (iframe), 
  height needs to be set based on container div width rather than actual window width */
  useLayoutEffect(() => {
    const updateHeightBasedOnWidth = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth

        if (containerWidth <= 768) {
          setCurrentHeight(mobileHeight)
        } else {
          setCurrentHeight(height)
        }
      }
    }

    updateHeightBasedOnWidth()

    const resizeObserver = new ResizeObserver(() => {
      updateHeightBasedOnWidth()
    })

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [height, mobileHeight])

  return (
    <div ref={containerRef} className="h-auto">
      {currentHeight !== undefined ? (
        <iframe
          src={src}
          style={{
            width: '100%',
            height: `${currentHeight}px`,
          }}
          className="rounded-xl shadow-[0px_0px_3px_0px_#1C1C2217,0px_6px_10px_0px_#1C1C220A,0px_10px_16px_0px_#1C1C2205]"
        />
      ) : (
        <HgLoadingDots className="justify-center" />
      )}
    </div>
  )
}

export default Iframe
