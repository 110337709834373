import {type EmptyObject} from '~/utils/types'
import {Method, SimpleAPI} from '~/mercuryWebBackend/SimpleAPI'

export type MagazineSubscribeParams = {
  email: string
}

export class MagazineSubscribe extends SimpleAPI<
  EmptyObject,
  MagazineSubscribeParams
> {
  revalidate = 'client-only' as const
  method = Method.POST

  getPath() {
    return '/magazine/subscribe'
  }

  protected async getMockResponse() {
    return {}
  }
}
