'use client'

import {type BaseSyntheticEvent} from 'react'
import {collectEventData} from '~/cms/pages/CMSPageLayout/_components/AnalyticsClickListener'
import {
  type HgEmailCaptureProps,
  type HgEmailFormData,
} from '~/design-system/hg/components'
import {unreachableCase} from '~/design-system/utils'
import {api} from '~/mercuryWebBackend/api'
import {type NewsletterList} from '~/mercuryWebBackend/endpoints/NewsletterSubscribe'
import {Analytics} from '~/utils/Analytics'

type GetEmailCaptureProps =
  | {
      destination: 'signup'
      signupUrl: string
    }
  | {
      destination: 'subscribe'
      list: NewsletterList
    }
export function getEmailCaptureProps(
  props: GetEmailCaptureProps
): Pick<HgEmailCaptureProps, 'formProps'> {
  const {destination} = props

  function trackAnalytics(
    type: 'signup' | 'subscribe',
    data: HgEmailFormData,
    e?: BaseSyntheticEvent<object, any, any>
  ) {
    const target = e?.target as HTMLElement | undefined

    const eventData = collectEventData(target ?? null)

    const pageVariant = eventData?.['data-page-variant']
    const page = eventData?.['data-page']
    const section = eventData?.['data-section']
    const theme = eventData?.['data-theme']

    Analytics.trackClick({
      type,
      location: section,
      customProperties: {
        page,
        pageVariant,
        theme,
        section,
        emailPrepopulated: data.email ? 'true' : 'false',
      },
    })
  }

  if (destination === 'signup') {
    const {signupUrl} = props

    return {
      formProps: {
        'data-analytics-event-name': 'signup',
        'data-analytics-disable-click-listener': 'true',
        'method': 'get',
        'action': signupUrl,
        'noValidate': true,
        'onSubmit'(data, e) {
          trackAnalytics('signup', data, e)
        },
      },
    }
  }

  if (destination === 'subscribe') {
    const {list} = props
    return {
      formProps: {
        'data-analytics-event-name': 'subscribe',
        'data-analytics-disable-click-listener': 'true',
        async 'onSubmit'(data, e) {
          trackAnalytics('subscribe', data, e)
          const {email} = data
          await api.newsletterSubscribe.fetch({
            params: {
              email,
              list,
              source: window.location.href,
            },
            pathPieces: {},
          })
        },
      },
    }
  }

  return unreachableCase(destination)
}
