'use client'

import Cookies from 'js-cookie'
import {type PropsWithChildren, createContext, use, useEffect, useState} from 'react'
import {FEM_ANONYMOUS_ID_COOKIE_NAME} from '~/config/cookies'
import {api} from '~/mercuryWebBackend/api'
import {type FemReferralAttributionResponse} from '~/mercuryWebBackend/endpoints/FEMReferralAttribution'
import {useSimpleMutation} from '~/mercuryWebBackend/useSimpleQuery'
import {Analytics} from '~/utils/Analytics'
import {notifyBugsnag} from '~/utils/Bugsnag/notify'
import {collectSignupAnalytics} from './collectSignupAnalytics'

type SignupAnalyticsContextProps = {
  data: FemReferralAttributionResponse | null
  setData: (data: FemReferralAttributionResponse) => void
}
const SignupAnalyticsContext = createContext<null | SignupAnalyticsContextProps>(
  null
)

export function useSignupAnalytics() {
  const context = use(SignupAnalyticsContext)
  if (!context) {
    throw new Error(
      'useSignupAnalytics must be used within a SignupAnalyticsProvider'
    )
  }

  return context
}

export function SignupAnalyticsProvider(props: PropsWithChildren) {
  const {children} = props
  const [data, setData] = useState<FemReferralAttributionResponse | null>(null)

  return (
    <SignupAnalyticsContext
      value={{
        data,
        setData,
      }}
    >
      {children}
    </SignupAnalyticsContext>
  )
}

export function SignupAnalytics() {
  const femReferralAttribution = useSimpleMutation(api.femReferralAttribution)
  const {setData} = useSignupAnalytics()

  useEffect(() => {
    async function run() {
      try {
        const femAnonymousUserIdCookie = Cookies.get(FEM_ANONYMOUS_ID_COOKIE_NAME)
        if (femAnonymousUserIdCookie) {
          await Analytics._identify({
            femAnonymousUserId: femAnonymousUserIdCookie,
          })
        }

        const signupAnalytics = collectSignupAnalytics()
        const response = await femReferralAttribution.mutateAsync({
          params: signupAnalytics,

          pathPieces: {},
        })

        setData(response)

        if (response.tag === 'skippedForSignedInUser') return
        const femAnonymousUserId =
          response.contents.femAnonymousUserId || femAnonymousUserIdCookie
        if (!femAnonymousUserId) return

        await Analytics._identify(response.contents)
      } catch (err: Error | unknown) {
        // Ignore network errors
        if (
          err instanceof Error &&
          [
            'Failed to fetch',
            'Load failed',
            'NetworkError when attempting to fetch resource.',
          ].includes(err.message)
        ) {
          return
        }
        notifyBugsnag('Unable to collect signup analytics', {
          caughtError: err,
        })
      }
    }
    void run()

    // This hook should only run once on the initial page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}
