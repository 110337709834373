'use client'

import {grid, gridItem} from '~/design-system/foundations'
import {
  HgParallaxImageGrid as HgParallaxImageGridComponent,
  type HgParallaxImageGridProps as HgParallaxImageGridComponentProps,
} from '~/design-system/hg/components'
import {useCallback, useEffect, useState} from 'react'
import {type OmitStrict} from 'type-zoo'

export type HgParallaxImageGridProps = OmitStrict<
  HgParallaxImageGridComponentProps,
  'animationThreshold'
>

export default function HgParallaxImageGrid({images}: HgParallaxImageGridProps) {
  const [animationThreshold, setAnimationThreshold] = useState(56)

  const calculateOffset = useCallback(() => {
    const value =
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--s8')
      ) || 56

    setAnimationThreshold(value)
  }, [])

  useEffect(() => {
    calculateOffset()

    window.addEventListener('resize', calculateOffset)
    return () => {
      window.removeEventListener('resize', calculateOffset)
    }
  }, [calculateOffset])

  return (
    <div className="w-full">
      <div
        className={grid({
          class: 'mx-auto pt-s12 md:px-s5',
          padding: false,
        })}
      >
        <div className={gridItem({size: 'max'})}>
          <HgParallaxImageGridComponent
            images={images}
            animationThreshold={animationThreshold}
          />
        </div>
      </div>
    </div>
  )
}
