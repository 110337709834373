import CheckCallsign from './endpoints/CheckCallsign'
import {ContactTreasury} from './endpoints/ContactTreasury'
import {FEMReferralAttribution} from './endpoints/FEMReferralAttribution'
import {Geolocate} from './endpoints/Geolocate'
import {ListInterestRates} from './endpoints/ListInterestRates'
import {MagazineSubscribe} from './endpoints/MagazineSubscribe'
import {NewsletterSubscribe} from './endpoints/NewsletterSubscribe'
import PartnerSignupOffers from './endpoints/PartnerSignupOffers'

export const api = {
  listInterestRates: new ListInterestRates(),
  newsletterSubscribe: new NewsletterSubscribe(),
  magazineSubscribe: new MagazineSubscribe(),
  contactTreasury: new ContactTreasury(),
  geolocate: new Geolocate(),
  femReferralAttribution: new FEMReferralAttribution(),
  partnerSignupOffers: new PartnerSignupOffers(),
  checkCallsign: new CheckCallsign(),
}
