'use client'
import {createContext, use, useRef} from 'react'
import {type Group, type Points, type Vector3} from 'three'

type SphereContextProps = {
  sphereRef: React.RefObject<Group | null>
  containerRef: React.RefObject<HTMLDivElement | null>
  lastMousePosition: React.RefObject<{x: number; y: number}>
  dragRotationVelocityRef: React.RefObject<{x: number; y: number}>
  lastMouseTime: React.RefObject<number>
  pointsRef: React.RefObject<Points | null>
  intersectionPoint: React.RefObject<Vector3 | null>
  pointerVelocity: React.RefObject<number>
  pointerDelta: React.RefObject<{deltaX: number; deltaY: number}>
}

const SphereContext = createContext<SphereContextProps | null>(null)

export default function SphereProvider({children}: {children: React.ReactNode}) {
  const sphereRef = useRef<Group | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const lastMousePosition = useRef({x: 0, y: 0})
  const dragRotationVelocityRef = useRef({x: 0, y: 0})
  const lastMouseTime = useRef(Date.now())
  const pointsRef = useRef<Points>(null)
  const intersectionPoint = useRef<Vector3 | null>(null)
  const pointerVelocity = useRef(0)
  const pointerDelta = useRef({deltaX: 0, deltaY: 0})

  const value = {
    sphereRef,
    containerRef,
    lastMousePosition,
    dragRotationVelocityRef,
    lastMouseTime,
    pointsRef,
    intersectionPoint,
    pointerVelocity,
    pointerDelta,
  }

  return <SphereContext value={value}>{children}</SphereContext>
}

export const useSphereContext = () => {
  const context = use(SphereContext)
  if (!context) {
    throw new Error('useSphereContext must be used within a SphereProvider')
  }
  return context
}
