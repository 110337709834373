/**
 * This is a rudimentary function to interact with the mercury-web-backend.
 * It expects everything to be manually typed and is not very robust. It should
 * be good enough to make requests for the FEM. We should eventually replace
 * this with a more automated system
 */

import {type EmptyObject} from 'react-hook-form'
import {mercuryWebBackendUrl} from '~/config/app'
import {env} from '~/config/env.mjs'
import {helpEmail} from '~/data/mercury'
import {notifyBugsnag} from '~/utils/Bugsnag/notify'
import {SimpleAPIUnknownError} from './errors/SimpleAPIUnknownError'
import {isProd} from '~/config/nodeEnv'

export enum Method {
  GET,
  POST,
}

export type Revalidate = number | 'on-demand' | 'client-only'

type FetchOptions<Params, PathPieces> = {
  fetchOptions?: RequestInit
  params: Params
  pathPieces: PathPieces
}

async function buildError(req: Response) {
  const res = await req.text()

  return new SimpleAPIUnknownError({
    contextualMessage: `We couldn't reach our servers. Please try refreshing the page or contact ${helpEmail} for help.`,
    rawResponseData: res,
    statusCode: req.status,
    statusText: req.statusText,
  })
}

export abstract class SimpleAPI<
  Response,
  Params = EmptyObject,
  PathPieces = EmptyObject,
> {
  abstract method: Method
  /**
   * `revalidate` is the number of seconds before the cache is invalidated.
   * If you elect to use `on-demand` you are responsible for manually calling the `revalidate` functions.
   * If you select `client-only`, then you are asserting that this request is only made on the client (e.g. form submit or with react-query)
   * See: https://nextjs.org/docs/app/building-your-application/data-fetching/fetching-caching-and-revalidating#revalidating-data
   */
  abstract revalidate: Revalidate

  /**
   * For server-server requests, we can use an internal network url (aka the MWB_URL env var) since:
   * 1. it avoids rate limits
   * 2. it's faster
   * However, since this link is only accessible from places that can access our internal servers (like AWS), we only want to use it when we know we are able to access it.
   */
  useInternalMWBUrl = false

  /**
   * Route specific response status codes that don't need to be reported to bugsnag
   */
  ignoredStatusCodes: number[] = []

  abstract getPath(pathPieces: PathPieces): string

  protected abstract getMockResponse(
    fetchOptions: FetchOptions<Params, PathPieces>
  ): Promise<Response>

  protected preProcessPostParams(params: Params): Params {
    return params
  }

  async fetch(
    opts: FetchOptions<Params, PathPieces>,
    retryCount: number = 0
  ): Promise<Response> {
    const {params, pathPieces, fetchOptions} = opts
    if (env.NEXT_PUBLIC_BACKEND_SOURCE === 'mock') {
      return await this.getMockResponse(opts)
    }

    /**
     * See the comment on `revalidate` for more information.
     * If a number is provided then we want to revalidate based on time.
     * Otherwise we are either revalidating on-demand or not at all.
     */
    const nextOptions =
      typeof this.revalidate === 'number' ? {revalidate: this.revalidate} : {}

    // For client requests, we can use the normal public MWB url
    const backendURL =
      isProd && this.useInternalMWBUrl ? env.MWB_URL : mercuryWebBackendUrl

    const queryParameters =
      this.method === Method.GET
        ? `?${new URLSearchParams(params as Record<string, string>).toString()}`
        : ''

    const req = await fetch(
      `${backendURL}${this.getPath(pathPieces)}${queryParameters}`,
      {
        method: fetchOptions?.method ?? Method[this.method],
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-PROTECT': '1',
          ...fetchOptions?.headers,
        },
        credentials: 'include',
        body:
          this.method === Method.GET
            ? undefined
            : JSON.stringify(this.preProcessPostParams(params)),
        next: nextOptions,
      }
    )

    if (!req.ok) {
      // TODO(CP-217): Handle the different status codes to surface the correct error type.
      // For now we are just throwing an unknown error

      const error = await buildError(req)

      /**
       * This differs from mercury-web. MW reports the error at the useQuery/useMutation level.
       * However since Next.js supports SSG, we may not always use those hooks. As a result the error handling
       * has been moved here.
       *
       * TODO(CP-217): Add filters, for example we don't want to report CSRF related errors to bugsnag
       */
      if (!this.ignoredStatusCodes.includes(req.status)) {
        notifyBugsnag(`SimpleAPI error: ${error.toHumanString()}`, {
          caughtError: error,
        })
      }

      throw error
    }

    if (req.status === 204 || req.headers.get('content-length') === '0') {
      // If the server returns a 204, it means there is no content in the response
      // And MWB is not consistent and sometimes returns empty responses :(
      return {} as Response
    }

    const res = await req.json()

    return res as Response
  }
}
